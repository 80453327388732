<template>
    <div class="max-width">
        <div v-html="$store.state.bottomInfo.hulianwang"></div>
    </div>
</template>

<script>
    export default {
        name: "serviceCertificate"
    }
</script>

<style scoped>

</style>
